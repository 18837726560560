/* globals widgetInit */
import { List, Map } from 'immutable'
import Cookies from 'js-cookie'
import _ from 'lodash'
import moment from 'moment-timezone'
import Theme from 'mgr/layout/Theme'
import { hexToRgb } from 'svr/common/Color'
import { ALL_LOCATIONS } from 'widget/dining/utils/convertData'
import { decodeQueryString } from '@sevenrooms/core/api'
import { camelCaseObject } from '../../../../common/ObjectUtils'
import { widgetLoaded } from './analyticsEvents'
import { searchTypes, viewTypes, CLIENT_PREFERRED_LANGUAGE_COOKIE } from './constantTypes'

const app = {}
app.env = widgetInit.app.env
app.isReservationWidget = widgetInit.app.isReservationWidget === 'True'
app.isWaitlistWidget = widgetInit.app.isWaitlistWidget === 'True'
app.isLandingPageWidget = widgetInit.app.isLandingPageWidget === 'True'
app.requestParams = camelCaseObject(decodeQueryString())
if (Object.prototype.hasOwnProperty.call(app.requestParams, '')) {
  app.requestParams = {}
}

const agent = navigator.userAgent || navigator.vendor || window.opera

/* eslint-disable */
const isMobile = () => {
  let check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    ) {
      check = true
    }
  })(agent)

  return check
}

const isAndroid = () => {
  let check = false
  ;(function (a) {
    if (/android/i.test(a)) {
      check = true
    }
  })(agent)

  return check
}

/* eslint-enable */
app.isMobile = isMobile()
app.isAndroid = isAndroid()

const widgetSettings = _.reduce(
  widgetInit.settings,
  (result, val, key) => {
    const camelKey = _.camelCase(key)
    if (_.startsWith(key, 'color')) {
      if (_.endsWith(key, 'opacity')) {
        return result
      }
      const rgbaColor = hexToRgb(val, widgetInit.settings[`${key}_opacity`] || 1)
      return _.assign(result, { [camelKey]: rgbaColor })
    }
    return _.assign(result, { [camelKey]: val })
  },
  {}
)

export const parseBoolean = val => {
  if (typeof val === 'boolean') {
    return val
  }
  return val === 'true'
}

widgetSettings.baseUrl = window.location.origin || `${window.location.protocol}//${window.location.host}`
widgetSettings.colorHover = hexToRgb(widgetSettings.fontsColorPrimary, 0.5)
widgetSettings.fontsColorInactive = hexToRgb(widgetSettings.fontsColorPrimary, 0.75)
widgetSettings.colorModalBackground = hexToRgb(widgetInit.settings.color_checkout_cell_background, 1)
widgetSettings.requireDietaryTagGdprOptIn = parseBoolean(widgetSettings.requireDietaryTagGdprOptIn)
widgetSettings.calendarOpen = parseBoolean(widgetSettings.calendarOpen)
widgetSettings.enableRequests = parseBoolean(widgetSettings.enableRequests)
widgetSettings.enablePromoCodes = parseBoolean(widgetSettings.enablePromoCodes)
widgetSettings.enableBonvoyLogin = parseBoolean(widgetSettings.enableBonvoyLogin)
widgetSettings.enableDietaryRestrictions = parseBoolean(widgetSettings.enableDietaryRestrictions)
widgetSettings.enableSpecialOccasions = parseBoolean(widgetSettings.enableSpecialOccasions)
widgetSettings.venueGroupMarketingOn = parseBoolean(widgetSettings.venueGroupMarketingOn)
widgetSettings.tailoredCommunicationOn = parseBoolean(widgetSettings.tailoredCommunicationOn)
widgetSettings.venueSpecificMarketingOn = parseBoolean(widgetSettings.venueSpecificMarketingOn)
widgetSettings.venueSmsMarketingOn = parseBoolean(widgetSettings.venueSmsMarketingOn)
widgetSettings.isFeesInPriceDisplayed = parseBoolean(widgetSettings.isFeesInPriceDisplayed)
widgetSettings.isVenueGroupMarketingPolicyDefaultOn = parseBoolean(widgetSettings.isVenueGroupMarketingPolicyDefaultOn)
widgetSettings.isTailoredCommunicationPolicyDefaultOn = parseBoolean(widgetSettings.isTailoredCommunicationPolicyDefaultOn)
widgetSettings.isTailoredCommunicationPolicyDisabled = parseBoolean(widgetSettings.isTailoredCommunicationPolicyDisabled)
widgetSettings.isVenueSpecificMarketingPolicyDefaultOn = parseBoolean(widgetSettings.isVenueSpecificMarketingPolicyDefaultOn)
widgetSettings.isVenueSmsMarketingPolicyDefaultOn = parseBoolean(widgetSettings.isVenueSmsMarketingPolicyDefaultOn)

widgetSettings.reservationSmsOptInOn = parseBoolean(widgetSettings.reservationSmsOptInOn)
widgetSettings.showSmsOptInSettingsForTwilioUs = parseBoolean(widgetSettings.showSmsOptInSettingsForTwilioUs)
widgetSettings.displayReservationSmsOptIn = parseBoolean(widgetSettings.displayReservationSmsOptIn)
widgetSettings.showSpecialAttentionMessage = parseBoolean(widgetSettings.showSpecialAttentionMessage)
widgetSettings.customCheckoutPolicyOn = parseBoolean(widgetSettings.customCheckoutPolicyOn)
widgetSettings.isDefaultCustomCheckoutPolicyOn = parseBoolean(widgetSettings.isDefaultCustomCheckoutPolicyOn)
widgetSettings.aboveAgeConsentOn = parseBoolean(widgetSettings.aboveAgeConsentOn)
widgetSettings.recaptchaOn = parseBoolean(widgetSettings.recaptchaOn)
widgetSettings.enableGiftCardRedemption = parseBoolean(widgetSettings.enableGiftCardRedemption)
widgetSettings.enableSocialMediaLogin = parseBoolean(widgetSettings.enableSocialMediaLogin)
widgetSettings.showVenueBanner = parseBoolean(widgetSettings.showVenueBanner)
widgetSettings.specificTimeSlots = widgetSettings.specificTimeSlots && JSON.parse(widgetSettings.specificTimeSlots)
widgetSettings.tripadvisorRefId = widgetInit.tripadvisorRefId
widgetSettings.showReservationLink = parseBoolean(widgetSettings.showReservationLink)
widgetSettings.showMap = parseBoolean(widgetSettings.showMap)
widgetSettings.showMenu = parseBoolean(widgetSettings.showMenu)
widgetSettings.hideQuoteTimes = parseBoolean(widgetSettings.hideQuoteTimes)
const widgetTheme = _.pick(widgetSettings, [
  'colorActionBarBackground',
  'colorBackground',
  'colorButton',
  'colorCellBackground',
  'colorCheckoutCellBackground',
  'colorError',
  'colorHover',
  'colorLines',
  'colorModalBackground',
  'colorPrimary',
  'colorSummaryBar',
  'colorWidgetBackground',
  'fontsColorActionBar',
  'fontsColorButton',
  'fontsColorCheckoutActive',
  'fontsColorCheckoutInactive',
  'fontsColorInactive',
  'fontsColorLinks',
  'fontsColorPrimary',
  'fontsColorSecondary',
  'fontsColorSummaryBar',
])

const theme = {
  ...widgetTheme,
  ...Theme,
}

const preloadedState = Map({
  isFetchingWidgetData: true,
  mediaUrl: widgetSettings.mediaUrl,
  errorMessage: null,
  widgetType: widgetInit.widgetType,
})
const featureFlags = camelCaseObject(widgetInit.featureFlags || {})

export const defaultRequestState = () => ({
  requestId: '',
  firstName: '',
  lastName: '',
  date: '',
  email: '',
  arrivalTime: '',
  maxGuests: 0,
  phoneNumber: '',
  phoneNumberLocale: '',
  requestSmsOptIn: false,
  timeRange: [],
  isActive: false,
  startDatetime: '',
  venueId: '',
})

let selectedVenue
// initialize venue info
const venueInfo = _.assign({}, camelCaseObject(_.omit(widgetInit.venueInfo, 'tag_groups', 'venue_today')), {
  venueUrlKey: window.location.pathname.split('/')[2],
})

const baseVenueName = venueInfo.venueName
// initialize group widget parameters
const venueMap = Map(widgetInit.venueInfo.venue_map)
  .map(val => val.venue_name)
  .set(ALL_LOCATIONS, widgetInit.settings.text_all_locations)
app.isMultiVenue = Object.keys(venueMap).length > 1

if (venueMap.get(venueInfo.urlKey)) {
  // cross-sell widget
  selectedVenue = venueInfo.urlKey
} else if (venueMap.size > 2) {
  // group widget, more than one venue
  selectedVenue = ALL_LOCATIONS
} else {
  selectedVenue = venueMap.keySeq().first()
}

// initialize modify res state
const modifyReservation = Map({
  isModifyResMode:
    JSON.parse(_.get(app, 'requestParams.modifyRes', 'false')) === true ||
    JSON.parse(_.get(app, 'requestParams.modifyUpgrades', 'false')) === true,
  token: _.get(app, 'requestParams.token', null),
  venueUrlKey: selectedVenue,
  hasCreditCardOnFile: false,
  isModifyResUpgradesMode: JSON.parse(_.get(app, 'requestParams.modifyUpgrades', 'false')) === true,
})

// set which type of widget it is
const requestId = _.get(app, 'requestParams.requestId', null)
const isRequestBookingMode = !!requestId

const experienceId = _.get(app, 'requestParams.experienceId', false)
const isExperienceMode = !!experienceId

const { waitlistId } = app.requestParams
const isWaitlistMode = app.isWaitlistWidget

const isLandingPageMode = app.isLandingPageWidget
const isReservationMode = app.isReservationWidget
const isModifyResUpgradesMode = modifyReservation.get('isModifyResUpgradesMode')

// initialize experience
const experience = Map({
  isExperienceMode,
  id: experienceId,
  experience: {
    name: '',
  },
})

// initialize request state
const reservationRequest = Map({
  isRequestBookingMode,
  id: requestId,
  reservationRequest: defaultRequestState(),
})

// initialize waitlist state
const waitlist = Map({
  id: waitlistId,
  status: null,
  isWaitlistMode,
  party_size: null,
  parties_ahead: null,
  start_wait_time: '',
  quoted_wait_time: null,
  wait_duration: 40,
  validPartySizes: [],
  isLoading: false,
  canBook: true,
  chosenAccessRuleId: null,
  isVenueOpen: false,
  isWaitlistOpen: false,
})

// log to GA that res wiget was loaded
if (isReservationMode) {
  widgetLoaded(baseVenueName)
}

// initialize sequence data
let viewSequence
if (isLandingPageMode) {
  viewSequence = List([viewTypes.LANDING_PAGE])
} else if (isWaitlistMode) {
  viewSequence = List([
    viewTypes.CHECKOUT_WAITLIST,
    viewTypes.CHOOSE_WAITLIST_EXPERIENCE,
    viewTypes.WAITING_ROOM,
    viewTypes.WAITLIST_CLOSED,
    viewTypes.VENUE_CLOSED,
  ])
} else if (isExperienceMode) {
  viewSequence = List([viewTypes.EXPERIENCES, viewTypes.LOGIN, viewTypes.CHECKOUT, viewTypes.CONFIRMATION, viewTypes.ERROR])
} else if (isModifyResUpgradesMode) {
  viewSequence = List([viewTypes.UPSELLS, viewTypes.CHECKOUT, viewTypes.CONFIRMATION, viewTypes.ERROR])
} else {
  viewSequence = List([
    viewTypes.SEARCH,
    viewTypes.SEARCH_RESULTS,
    viewTypes.LOGIN,
    viewTypes.CHECKOUT,
    viewTypes.CONFIRMATION,
    viewTypes.ERROR,
  ])
}

// initialize stage where we should start
let _stage = 0
if (widgetInit.successBypass) {
  _stage = 4
} else if (widgetInit.errorDisplay) {
  _stage = 5
}

// initialize UI
const ui = Map({
  calendarDateMoment: null,
  displayModalType: null,
  displaySearchType: widgetSettings.calendarOpen ? searchTypes.DATE : null,
  modalMessage: '',
  stage: _stage,
  viewSequence,
})

// initialize client info
const clientInfo = camelCaseObject(widgetInit.clientInfo)

// initialize search state
const [month, day, year] = widgetInit.venueInfo.venue_today.split('/')
const venueTodayMoment = moment()
  .tz(venueInfo.timezone)
  .year(20 + year)
  .month(month - 1)
  .date(day) // eslint-disable-line
const maxDaysOut = Number(app.requestParams.maxDaysOut || widgetSettings.maxDaysOut)

const search = Map({
  venueToday: venueTodayMoment,
  maxDaysOut,
})

// initialize part of language data
const venueLanguages = JSON.parse(venueInfo.venueLanguagesJson)
const urlLanguage = app.requestParams.lang
const urlLanguageValid = urlLanguage && _.find(venueLanguages, { value: urlLanguage, is_enabled: true })
const selectedLanguage = urlLanguageValid ? urlLanguage : venueInfo.defaultLanguageCode

const languages = {
  clientPreferredLanguage: Cookies.get(CLIENT_PREFERRED_LANGUAGE_COOKIE),
  selectedLanguage,
}

export default {
  preloadedState,
  venueInfo, // gets partially loaded, bulk loads after preload
  clientInfo,
  app,
  widgetSettings,
  theme,
  formFields: Map(), // loads after preload
  entities: { tags: Map() }, // loads after preload
  search, // gets partially loaded, loads after preload
  ui, // gets partially loaded, loads after preload
  venueEntities: {}, // loads after preload
  modifyReservation,
  experience,
  reservationRequest,
  waitlist,
  languages, // gets partially loaded, loads after preload
  featureFlags,
}
