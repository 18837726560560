import _ from 'lodash'
import { CLIENT_CHANGE_PREFERRED_LANGUAGE } from 'mgr/pages/single-venue/settings/actions/ActionTypes'
import {
  INITIALIZE,
  INITIALIZE_EXPERIENCE_MODE,
  REVERT_STAGE,
  ADVANCE_STAGE,
  TOGGLE_FIELD,
  INCREMENT_ITEM_DATE,
  DECREMENT_ITEM_DATE,
  INCREMENT_ITEM_PARTY_SIZE,
  DECREMENT_ITEM_PARTY_SIZE,
  INCREMENT_ITEM_DURATION,
  DECREMENT_ITEM_DURATION,
  INCREMENT_ITEM_TIME,
  DECREMENT_ITEM_TIME,
  DISPLAY_MODAL,
  DISMISS_MODAL,
  SELECT_TIME_SLOT,
  SELECT_REQUEST,
  TOGGLE_SEARCH_COMPONENT,
  SELECT_QUERY_DATE,
  SELECT_QUERY_PARTY_SIZE,
  SELECT_QUERY_DURATION,
  SELECT_QUERY_TIME,
  SELECT_REQUEST_TIME,
  SELECT_REQUEST_END_TIME,
  SELECT_VENUE,
  CONFIRM_TIME_SLOT,
  TRY_AGAIN,
  REMOVE_PROMO_CODE,
  INCREMENT_CALENDAR_MONTH,
  DECREMENT_CALENDAR_MONTH,
  INCREMENT_WAITLIST_PARTY_SIZE,
  DECREMENT_WAITLIST_PARTY_SIZE,
  GO_TO_WAITING_ROOM,
  SET_PAGE_TITLE,
  SELECT_TIME_SLOT_VENUE,
} from 'widget/dining/actions/ActionTypes'
import { getVenueLanguageCodeInformation, tryGetWidgetLanguageStrings } from 'widget/dining/actions/language'
// eslint-disable-next-line import/no-cycle
import {
  submitHoldReservation,
  tryGetPromoCodes,
  tryGetExperience,
  tryGetInitialAvailability,
  tryGetReservationRequest,
} from 'widget/dining/actions/services'
import { selectQueryMoment } from 'widget/dining/SearchResults/selectors'
import { doShowDiningAdditionalSelectionModal } from 'widget/dining/utils/conditions'
import { parseBoolean } from 'widget/dining/utils/preloadedState'
import { getSelectedVenueFullName, getVenueInternalName } from 'widget/dining/utils/venueHelpers'
import * as AnalyticsEvents from '../utils/analyticsEvents'
import { resetVenueSpecificWidgetFormData } from './loadWidgetData'

export const initialize = () => dispatch => {
  dispatch({ type: INITIALIZE })
}

export const initializeExperienceMode = () => (dispatch, getState) => {
  const state = getState()

  dispatch(tryGetExperience(state.experience.get('id')))

  dispatch({ type: INITIALIZE_EXPERIENCE_MODE })
}

export const initializeRequestBookingMode = () => (dispatch, getState) => {
  const state = getState()
  dispatch(tryGetReservationRequest(state.reservationRequest.get('id')))
}

export const revertStage = (toStage, resetReCaptcha) => (dispatch, getState) => {
  const state = getState()
  const stage = state.ui.get('stage') - 1
  const viewSequence = state.ui.get('viewSequence').toJS()
  const nextView = viewSequence[stage]
  const selectedVenue = state.search.get('selectedVenue')
  const { venueEntities, venueInfo } = state
  const { defaultLanguageCode, enabledLanguageCodes } = getVenueLanguageCodeInformation(venueEntities, venueInfo, selectedVenue)
  AnalyticsEvents.backButtonPressed()
  dispatch({
    type: REVERT_STAGE,
    toStage: stage,
    hasClientToken: !_.isEmpty(getState().clientInfo),
    resetReCaptcha,
    nextView,
    selectedVenue,
    enabledLanguageCodes,
    defaultLanguageCode,
  })
}

export const advanceStage = () => {
  AnalyticsEvents.advanceForward()
  return { type: ADVANCE_STAGE }
}

export const incrementWaitlistPartySize = maxGuests => ({
  type: INCREMENT_WAITLIST_PARTY_SIZE,
  maxGuests,
})
export const decrementWaitlistPartySize = minGuests => ({
  type: DECREMENT_WAITLIST_PARTY_SIZE,
  minGuests,
})

export const incrementItemDate = () => (dispatch, getState) => {
  const state = getState()
  const selectedVenueFullName = getSelectedVenueFullNameFromState(state)
  return dispatch({ type: INCREMENT_ITEM_DATE, selectedVenueFullName })
}

export const decrementItemDate = () => (dispatch, getState) => {
  const state = getState()
  const selectedVenueFullName = getSelectedVenueFullNameFromState(state)
  return dispatch({ type: DECREMENT_ITEM_DATE, selectedVenueFullName })
}

export const incrementItemPartySize = () => (dispatch, getState) => {
  const state = getState()
  const queryMoment = selectQueryMoment(state)
  const isExperienceMode = state.experience.get('isExperienceMode')

  let lastDispatch = dispatch({
    type: INCREMENT_ITEM_PARTY_SIZE,
  })
  if (isExperienceMode) {
    lastDispatch = dispatch(tryGetInitialAvailability(queryMoment))
  }
  return lastDispatch
}

export const decrementItemPartySize = () => (dispatch, getState) => {
  const state = getState()
  const queryMoment = selectQueryMoment(state)
  const isExperienceMode = state.experience.get('isExperienceMode')

  let lastDispatch = dispatch({
    type: DECREMENT_ITEM_PARTY_SIZE,
  })
  if (isExperienceMode) {
    lastDispatch = dispatch(tryGetInitialAvailability(queryMoment))
  }
  return lastDispatch
}

export const incrementItemDuration = () => dispatch => {
  dispatch({
    type: INCREMENT_ITEM_DURATION,
  })
}

export const decrementItemDuration = () => dispatch => {
  dispatch({
    type: DECREMENT_ITEM_DURATION,
  })
}

export const incrementItemTime = () => ({ type: INCREMENT_ITEM_TIME })

export const decrementItemTime = () => ({ type: DECREMENT_ITEM_TIME })

export const toggleField = field => ({ type: TOGGLE_FIELD, field })

export const toggleModalDisplay = modal => ({ type: DISPLAY_MODAL, modal })

export const dismissModal = () => ({ type: DISMISS_MODAL })

const _isTimeSlotRequiresConfirmStep = selectedSlot => {
  const { publicLongFormDescription, publicDescriptionTitle, publicPhoto, selectedAutomaticUpsells } = selectedSlot
  return doShowDiningAdditionalSelectionModal(publicLongFormDescription, publicDescriptionTitle, publicPhoto, selectedAutomaticUpsells)
}

export const selectTimeSlot = selectedSlot => (dispatch, getState) => {
  const { venueKey, timeSlotMoment, accessPersistentId } = selectedSlot
  const state = getState()
  const timeSlotSkipsConfirmStep = !_isTimeSlotRequiresConfirmStep(selectedSlot)
  // Typically we Hold before Selecting, but some access rules have a Confirm step first, in which case we fire the Hold only upon confirming the selection
  const doHoldReservation = state.widgetSettings.reservationHoldEnabled && timeSlotSkipsConfirmStep
  const search = state.search.toJS()
  const { selectedVenue } = search
  return (doHoldReservation ? dispatch(submitHoldReservation(venueKey, timeSlotMoment, accessPersistentId)) : Promise.resolve())
    .then(timeSlotSkipsConfirmStep ? () => dispatch(tryGetWidgetLanguageStrings(venueKey)) : Promise.resolve())
    .then(() => {
      _dispatchSelectedTimeSlot(dispatch, getState, selectedSlot)
      dispatch(tryGetPromoCodes(selectedSlot))
      dispatch(resetVenueSpecificWidgetFormData(selectedSlot))
      AnalyticsEvents.selectTimeSlot({
        venue: getSelectedVenueFullName(selectedVenue, search),
        time: selectedSlot.timeSlotMoment.format('h:mm a'),
        date: selectedSlot.timeSlotMoment.format('MM-DD-Y'),
      })
    })
    .catch(e => {
      // eslint-disable-next-line no-console
      console.log('Ignoring failed hold request', e)
    })
}

export const tryAgain = () => ({ type: TRY_AGAIN })

export const confirmTimeSlot = () => (dispatch, getState) => {
  const state = getState()
  const { venueKey, timeSlotMoment, accessPersistentId } = state.reservationHold.toJS()
  return (
    state.widgetSettings.reservationHoldEnabled
      ? dispatch(submitHoldReservation(venueKey, timeSlotMoment, accessPersistentId))
      : Promise.resolve()
  )
    .then(() => dispatch(tryGetWidgetLanguageStrings(venueKey)))
    .then(() => _dispatchConfirmTimeSlot(dispatch))
    .catch(e => {
      // eslint-disable-next-line no-console
      console.log('Ignoring failed hold request', e)
    })
}

const _dispatchConfirmTimeSlot = dispatch => {
  dispatch({ type: CONFIRM_TIME_SLOT })
}

const _dispatchSelectedTimeSlot = (dispatch, getState, selectedSlot) => {
  const state = getState()
  const {
    timeSlotMoment,
    venueKey,
    accessPersistentId,
    publicDescriptionTitle,
    publicLongFormDescription,
    publicPhoto,
    publicPhotoSizes,
    cost,
    reservationCost,
    selectedAutomaticUpsells,
    reservationFees,
  } = selectedSlot
  const dateKey = timeSlotMoment.format('Y-MM-DD')
  const timeKey = timeSlotMoment.format('h:mm A')
  const availabilityEntity = state.availabilityLookup.getIn([venueKey, dateKey, timeKey, accessPersistentId]).toJS()
  const isSocialMediaLoginEnabled = state.widgetSettings.enableSocialMediaLogin
  const isInstantExperience = !!_.get(state, 'app.requestParams.instantExperiencesEnabled')
  const isModifyResMode = state.modifyReservation.get('isModifyResMode')
  const isRequestBookingMode = state.reservationRequest.get('isRequestBookingMode')
  const upsellCategories = availabilityEntity.upsellCategories || []
  const timeSlotHasUpsells =
    availabilityEntity.upsellCategories &&
    _.some(
      availabilityEntity.upsellCategories,
      upsellCategoryId =>
        state.upsells.entities.categories[upsellCategoryId] && !_.isEmpty(state.upsells.entities.categories[upsellCategoryId].inventories)
    )
  const { cancellationPolicy } = availabilityEntity

  const venueGroupMarketingPolicy = parseBoolean(state.venueEntities[venueKey].defaultVenueGroupMarketingOn)
  const tailoredCommunicationPolicy = parseBoolean(state.venueEntities[venueKey].defaultTailoredCommunicationOn)
  const venueSpecificMarketingPolicy = parseBoolean(state.venueEntities[venueKey].defaultVenueSpecificMarketingOn)
  const aboveAgeConsentPolicy = parseBoolean(state.venueEntities[venueKey].defaultAboveAgeConsentOn)
  const displayReservationSmsOptInPolicy = parseBoolean(state.venueEntities[venueKey].displayReservationSmsOptIn)
  const displayTailoredCommunicationOptInPolicy = parseBoolean(state.venueEntities[venueKey].displayTailoredCommunicationOptIn)
  const reservationSmsOptInOn = parseBoolean(state.venueEntities[venueKey].reservationSmsOptInOn)
  const tailoredCommunicationOptIn = parseBoolean(state.venueEntities[venueKey].tailoredCommunicationOptIn)
  const showSmsOptInSettingsForTwilioUs = parseBoolean(state.venueEntities[venueKey].showSmsOptInSettingsForTwilioUs)
  const partySize = state.search.get('partySize')
  const duration = state.search.get('duration')
  const {
    textVenueGroupMarketingOptIn,
    textVenueSpecificMarketingOptIn,
    textReservationSmsOptIn,
    textTailoredCommunicationOptInLabel,
    textTailoredCommunicationOptInHeader,
    textTailoredCommunicationOptInBody,
  } = state.venueEntities[venueKey]
  const hasClientToken = !_.isEmpty(state.clientInfo)
  const firstName = hasClientToken ? state.clientInfo.firstName : ''
  const lastName = hasClientToken ? state.clientInfo.lastName : ''
  const email = hasClientToken ? state.clientInfo.email : ''
  const phoneNumber = hasClientToken ? state.clientInfo.phoneNumberLocalized.toString() : ''
  const phoneNumberLocale = hasClientToken ? state.clientInfo.phoneNumberLocale : state.formFields.get('phoneNumberLocale')
  const dialCode = hasClientToken ? state.clientInfo.dialcode : state.formFields.get('dialCode')
  const agreedToReservationSmsOptIn = showSmsOptInSettingsForTwilioUs && displayReservationSmsOptInPolicy && reservationSmsOptInOn
  const agreedToTailoredCommunicationOptIn = displayTailoredCommunicationOptInPolicy && tailoredCommunicationOptIn
  const fees = reservationFees ?? 0
  dispatch({ type: REMOVE_PROMO_CODE })
  dispatch({
    type: SELECT_TIME_SLOT,
    isSocialMediaLoginEnabled,
    timeSlotMoment,
    venueKey,
    accessPersistentId,
    publicDescriptionTitle,
    publicLongFormDescription,
    publicPhoto,
    publicPhotoSizes,
    availabilityEntity,
    timeSlotHasUpsells,
    cancellationPolicy,
    customCheckoutPolicy: availabilityEntity.customCheckoutPolicy,
    venueGroupMarketingPolicy,
    tailoredCommunicationPolicy,
    venueSpecificMarketingPolicy,
    aboveAgeConsentPolicy,
    textVenueGroupMarketingOptIn,
    textVenueSpecificMarketingOptIn,
    textReservationSmsOptIn,
    displayReservationSmsOptInPolicy,
    displayTailoredCommunicationOptInPolicy,
    agreedToReservationSmsOptIn,
    agreedToTailoredCommunicationOptIn,
    textTailoredCommunicationOptInLabel,
    textTailoredCommunicationOptInHeader,
    textTailoredCommunicationOptInBody,
    upsellCategories,
    partySize,
    duration,
    isInstantExperience,
    isModifyResMode,
    isRequestBookingMode,
    cost,
    reservationCost,
    selectedAutomaticUpsells,
    hasClientToken,
    firstName,
    lastName,
    email,
    phoneNumber,
    phoneNumberLocale,
    dialCode,
    fees,
  })
}

export const selectRequest = () => ({
  type: SELECT_REQUEST,
})

export const toggleSearchComponent = item => ({
  type: TOGGLE_SEARCH_COMPONENT,
  item,
})

export const selectQueryDate = dateKey => (dispatch, getState) => {
  const state = getState()
  const selectedVenueFullName = getSelectedVenueFullNameFromState(state)
  const queryMoment = selectQueryMoment(state)

  let lastDispatch = dispatch({ type: SELECT_QUERY_DATE, dateKey, selectedVenueFullName })

  if (state.experience.get('isExperienceMode')) {
    lastDispatch = dispatch(tryGetInitialAvailability(queryMoment))
  }
  return lastDispatch
}

export const selectQueryPartySize = partySize => (dispatch, getState) => {
  const state = getState()
  const queryMoment = selectQueryMoment(state)

  let lastDispatch = dispatch({
    type: SELECT_QUERY_PARTY_SIZE,
    partySize,
  })
  if (state.experience.get('isExperienceMode')) {
    lastDispatch = dispatch(tryGetInitialAvailability(queryMoment))
  }
  return lastDispatch
}

export const selectQueryDuration = duration => ({
  type: SELECT_QUERY_DURATION,
  duration,
})

export const selectQueryTime = (timeMoment, bookingDateTimeMoment = null, isModifyResUpgradesMode = false) => ({
  type: SELECT_QUERY_TIME,
  timeMoment,
  bookingDateTimeMoment,
  isModifyResUpgradesMode,
})

export const selectRequestTime = requestTime => ({
  type: SELECT_REQUEST_TIME,
  requestTime,
})

export const selectRequestEndTime = requestEndTime => ({
  type: SELECT_REQUEST_END_TIME,
  requestEndTime,
})

export const selectVenue = venue => (dispatch, getState) => {
  const state = getState()
  const venueInternalName = getVenueInternalName(venue, state)
  const { search, venueInfo, venueEntities } = state
  const selectedVenue = getSelectedVenueFullName(venue, search.toJS())
  const { defaultLanguageCode, enabledLanguageCodes } = getVenueLanguageCodeInformation(venueEntities, venueInfo, venue)
  AnalyticsEvents.selectVenue(selectedVenue, venueInternalName)
  dispatch({
    type: SELECT_VENUE,
    venue,
    defaultLanguageCode,
    enabledLanguageCodes,
  })
}

export const clientChangedPreferredLanguage = languageCode => {
  AnalyticsEvents.changePreferredLanguage(languageCode)
  return {
    type: CLIENT_CHANGE_PREFERRED_LANGUAGE,
    languageCode,
  }
}

export const selectUpsells = () => (dispatch, getState) => {
  const state = getState()
  const { upsells } = state
  AnalyticsEvents.completeUpgradesPage(upsells)
  dispatch(advanceStage())
}

export const incrementCalendarMonth = () => ({ type: INCREMENT_CALENDAR_MONTH })

export const decrementCalendarMonth = () => ({ type: DECREMENT_CALENDAR_MONTH })

export const goToWaitingRoom = () => ({ type: GO_TO_WAITING_ROOM })

export const setPageTitle = title => ({
  type: SET_PAGE_TITLE,
  title,
})

export const selectQueryTimeSlotVenue = selectedVenue => ({
  type: SELECT_TIME_SLOT_VENUE,
  selectedVenue,
})

const getSelectedVenueFullNameFromState = state => {
  const search = state.search.toJS()
  const { selectedVenue } = search
  return getSelectedVenueFullName(selectedVenue, search)
}
