// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-redeclare
/* global Stripe, FormData */
import flex from '@cybersource/flex-sdk-web'
import fetch from 'isomorphic-fetch'
import 'whatwg-fetch'
import _ from 'lodash'
import moment from 'moment-timezone'
import { stripeCard } from 'svr/common/stripeCard'
import { setDataFor3Dsecure } from 'svr/component-lib/Widget/Payments/actions'
import { SET_STRIPE_INTENT_CLIENT_SECRET } from 'svr/component-lib/Widget/Payments/ActionTypes'
import { AccountTypes, PAYMENT_CHANNELS, TransactionTypes } from 'svr/lib/Payments/Constants'
import { CardCodes } from 'svr/lib/Payments/CyberSource'
import { RSAEncrypt } from 'svr/lib/Payments/FreedomPay'
import { FREEDOMPAY_SOFT_DECLINE_CODES } from 'svr/lib/Payments/FreedomPayGateway'
import { AESEncrypt, PipeFormat } from 'svr/lib/Payments/Network'
// eslint-disable-next-line import/no-cycle
import { IntentSources } from 'svr/lib/Payments/StripeGateway'
// eslint-disable-next-line import/no-cycle
import {
  initialize,
  revertStage,
  selectQueryDate,
  selectQueryDuration,
  selectQueryPartySize,
  selectQueryTime,
  selectQueryTimeSlotVenue,
  toggleModalDisplay,
} from 'widget/dining/actions/navigation'
import { deriveQueryMoment } from 'widget/dining/SearchResults/selectors'
import { selectLanguageStrings } from 'widget/dining/selectors/languageSelectors'
import { getSelectedVenueFullName, getVenueInternalName } from 'widget/dining/utils/venueHelpers'
import * as PaymentResultStatus from 'widget/universal/components/payments/response'
import { isUnifiedPaymentResponse } from 'widget/universal/components/payments/util'
import { RequestService } from '@sevenrooms/core/api'
import { selectPricingData, selectVenuePaymentType } from '../payments/paymentSelectors'
import * as AnalyticsEvents from '../utils/analyticsEvents'
import { fBPixelTrack } from '../utils/analyticsEvents'
import { viewTypes } from '../utils/constantTypes'
import {
  ALL_LOCATIONS,
  convertToFormData,
  prepCaptchaData,
  prepChargeData,
  prepDataForModify,
  prepHoldReservationData,
  prepReleaseReservationHoldData,
  prepRequestData,
  prepResData,
  prepTags,
  prepUserData,
  prepUserRequestData,
  prepWaitlistData,
  removeCountryCodeFromPhone,
} from '../utils/convertData'
import * as ActionTypes from './ActionTypes'
import {
  cleanCybersourcePaymentInformation,
  payerAuthenticationCheckEnrollmentComplete,
  payerAuthenticationSetupComplete,
} from './cybersourceThreeDs'

export const getValidDatesSuccess = data => ({
  type: ActionTypes.GET_VALID_DATES_SUCCESS,
  data,
})

export const getValidExperienceAvailability = data => (dispatch, getState) => {
  const state = getState()
  const firstAvailableExperienceDate = state.availability.get('firstAvailableExperienceDate')

  dispatch({
    type: ActionTypes.GET_EXPERIENCE_AVAILABILITY_SUCCESS,
    data,
    firstAvailableExperienceDate,
  })
  if (!firstAvailableExperienceDate && data.firstAvailable) {
    const dateMoment = moment(data.firstAvailable)
    const timeMoment = state.search.get('timeMoment')
    const queryMoment = deriveQueryMoment(dateMoment, timeMoment)
    dispatch(tryGetInitialAvailability(queryMoment))
  }
}

export const getValidExperienceAvailabilityDone = () => ({
  type: ActionTypes.GET_EXPERIENCE_AVAILABILITY_DONE,
})

export const tryGetInitialAvailability = queryMoment => (dispatch, getState) => {
  const state = getState()
  const search = state.search.toJS()
  const { selectedVenue } = search
  const analyticsSearchData = {
    venue: getSelectedVenueFullName(selectedVenue, search),
    date: search.dateMoment.format('MM-DD-Y'),
    time: search.timeMoment.format('h:mm a'),
    partySize: search.partySize,
    venueInternalName: getVenueInternalName(selectedVenue, state),
  }
  AnalyticsEvents.searchButtonClicked(analyticsSearchData)
  AnalyticsEvents.search(analyticsSearchData)
  dispatch({
    type: ActionTypes.TRY_GET_INITIAL_AVAILABILITY,
    queryMoment,
    spinnerModalMessage: selectLanguageStrings(state).textSpinnerFindingLabel,
    isModifyResUpgradesMode: state.modifyReservation.get('isModifyResUpgradesMode'),
  })
}

export const getInitialAvailabilitySuccess = data => (dispatch, getState) => {
  const state = getState()
  dispatch({
    type: ActionTypes.GET_INITIAL_AVAILABILITY_SUCCESS,
    data,
    isExperienceMode: state.experience.get('isExperienceMode'),
    isModifyResUpgradesMode: state.modifyReservation.get('isModifyResUpgradesMode'),
  })
}

export const getInitialAvailabilityFailure = data => ({
  type: ActionTypes.GET_INITIAL_AVAILABILITY_FAILURE,
  data,
})

export const tryGetMultiVenueAvailability = queryMoment => (dispatch, getState) => {
  const state = getState()
  const search = state.search.toJS()
  const { selectedVenue } = search
  AnalyticsEvents.search({
    venue: getSelectedVenueFullName(selectedVenue, search),
    date: search.timeMoment.format('MM-DD-Y'),
    time: search.timeMoment.format('h:mm a'),
    partySize: search.partySize,
    venueInternalName: getVenueInternalName(selectedVenue, state),
  })
  return dispatch({
    type: ActionTypes.TRY_GET_MULTI_VENUE_AVAILABILITY,
    queryMoment,
    spinnerModalMessage: selectLanguageStrings(getState()).textSpinnerFindingLabel,
    isModifyResUpgradesMode: state.modifyReservation.get('isModifyResUpgradesMode'),
  })
}

export const getMultiVenueAvailabilitySuccess = data => (dispatch, getState) => {
  const state = getState()
  const isModifyResUpgradesMode = state.modifyReservation.get('isModifyResUpgradesMode')
  dispatch({
    type: ActionTypes.GET_MULTI_VENUE_AVAILABILITY_SUCCESS,
    data,
    isExperienceMode: getState().experience.get('isExperienceMode'),
    isModifyResUpgradesMode,
  })

  if (isModifyResUpgradesMode) {
    const selectedTimeSlot = state.search.get('selectedTimeSlot')
    const dateStr = selectedTimeSlot.format('Y-MM-DD')
    const formattedTimeStr = selectedTimeSlot.format('h:mm A')
    const timeSlotVenue = state.search.get('selectedVenue')
    const actual = state.modifyReservation.get('actual')
    const accessPersistentId = actual.get('access_persistent_id')
    if (accessPersistentId) {
      const setAccessPersistentId = accessPersistentId => {
        dispatch({
          type: ActionTypes.SET_ACCESS_PERSISTENT_ID,
          accessPersistentId,
        })
      }
      if (state.availabilityLookup.getIn([timeSlotVenue, dateStr, formattedTimeStr, accessPersistentId])) {
        setAccessPersistentId(accessPersistentId)
      } else {
        const shiftIntervalMinutes = state.entities.shift.get('shiftIntervalMinutes')
        const nextAvailableTimeStr = selectedTimeSlot.add(shiftIntervalMinutes, 'minutes').format('h:mm A')
        if (state.availabilityLookup.getIn([timeSlotVenue, dateStr, nextAvailableTimeStr, accessPersistentId])) {
          setAccessPersistentId(accessPersistentId)
        } else {
          const token = state.modifyReservation.get('token')
          const { selectedLanguage } = state.languages
          window.location.replace(`/direct/modify-reservation/${token}?lang=${selectedLanguage}`)
        }
      }
    }
  }
}

export const getAdditionalAvailabilitySuccess = data => ({
  type: ActionTypes.GET_ADDITIONAL_AVAILABILITY_SUCCESS,
  data,
})

export const getAdditionalAvailabilityComplete = () => ({
  type: ActionTypes.GET_ADDITIONAL_AVAILABILITY_COMPLETE,
})

const tryPostCheckout = state => ({
  type: ActionTypes.TRY_POST_CHECKOUT,
  spinnerModalMessage: selectLanguageStrings(state).textSpinnerRequestProcessingLabel,
})

const postCheckoutSuccess = () => (dispatch, getState) => {
  const state = getState()
  const isModifyResUpgradesMode = state.modifyReservation.get('isModifyResUpgradesMode')
  const venueUrlKey = state.modifyReservation.get('venueUrlKey')
  const { selectedLanguage } = state.languages
  dispatch({ type: ActionTypes.POST_CHECKOUT_SUCCESS })
  if (isModifyResUpgradesMode) {
    setTimeout(() => {
      window.location.replace(`/reservations/${venueUrlKey}?lang=${selectedLanguage}`)
    }, 8000)
  }
}

export const postCheckoutFailure = errorMessage => ({
  type: ActionTypes.POST_CHECKOUT_FAILURE,
  errorMessage,
})

export const postCheckoutPageReloadOnFailure = (errorMessage, actionText, actionCallback) => ({
  type: ActionTypes.POST_CHECKOUT_RELOAD_ON_FAILURE,
  errorMessage,
  actionText,
  actionCallback,
})

const setPaymentPendingResponse = paymentPendingResponse => ({
  type: ActionTypes.SET_PAYMENT_PENDING_RESPONSE,
  paymentPendingResponse,
})

export const hideLoading = () => ({
  type: ActionTypes.HIDE_LOADING,
})

export const stripeError = errorMessage => ({
  type: ActionTypes.STRIPE_ERROR,
  errorMessage,
})

export const setIntentClientSecret = stripeIntentClientSecret => ({
  type: SET_STRIPE_INTENT_CLIENT_SECRET,
  stripeIntentClientSecret,
})

const extractCheckoutData = (data, tagGroups) => {
  const tags = {
    SPECIAL_OCCASION: [],
    DIETARY_PREFERENCE: [],
  }

  if (tagGroups) {
    _.forIn(tagGroups, group => {
      tags[group.type] = tags[group.type].concat(_.keys(group.selectedTags))
    })
  }

  return {
    partySize: data.party_size,
    date: data.date,
    time: data.time || data.est_arrival_time,
    charge: data.charge_amount,
    marketingOptIn: data.marketing_opt_in,
    dietaryRestrictions: tags.DIETARY_PREFERENCE,
    specialOccasions: tags.SPECIAL_OCCASION,
    email: data.email,
  }
}

// eslint-disable-next-line consistent-return
const postCheckout = (url, formData, jsonData, dispatch, state) => {
  dispatch(tryPostCheckout(state))
  const searchState = state.search.toJS()
  const timeSlotVenueKey = state.searchResults.get('timeSlotVenue')
  const venue = state.venueEntities[timeSlotVenueKey]
  const { venueId } = venue

  const paymentType = selectVenuePaymentType(state)

  if (jsonData.card_token && venue.threedSecure && paymentType === AccountTypes.NETWORK) {
    // eslint-disable-next-line no-param-reassign
    jsonData.venues = getVenueKeysQueryString(searchState.venueMap)
    const form = document.createElement('form')
    form.method = 'post'
    form.action = `/booking/${venueId}/3d_secure_routing/`

    for (const key in jsonData) {
      if (jsonData[key] === undefined) {
        continue
      }
      const input = document.createElement('input')
      input.name = key
      input.value = jsonData[key]
      form.appendChild(input)
    }

    const router = document.createElement('input')
    router.name = 'router'
    router.value = 'dining'
    form.appendChild(router)

    fBPixelTrack('Schedule')

    document.body.appendChild(form)
    return form.submit()
  }

  fetch(url, {
    body: formData,
    method: 'POST',
    credentials: 'same-origin',
  })
    .then(response => response.json())
    .then(response => {
      const paymentType = selectVenuePaymentType(state)

      if (!response.errors) {
        const { tagGroups } = state.entities.tags.toJSON()
        const checkoutData = extractCheckoutData(jsonData, tagGroups)
        const searchState = state.search.toJS()
        const { timeSlotVenue } = state.searchResults.toJS()
        const currencyParam = _.get(state, 'venueInfo.currencyCode', 'USD')
        AnalyticsEvents.successfulCheckout(
          { referenceCode: response.message || response, ...checkoutData },
          getSelectedVenueFullName(timeSlotVenue, searchState),
          getVenueInternalName(timeSlotVenue, state),
          venueId,
          currencyParam
        )
        const value = checkoutData.charge || 0

        AnalyticsEvents.currencyConversion(value, currencyParam)

        const { last_confirmation_method: lastConfirmationMethod } = response
        dispatch(setLastConfirmationMethod(lastConfirmationMethod))

        window.restaurant_data = {
          id: venue.urlKey,
          name: venue.venueName,
          phone_country: state.formFields.toJS().phoneNumberLocale,
          dine_date: moment(checkoutData.date).format('ddd, MMMM D'),
          dine_time_category: moment(checkoutData.time, ['h:mm A']).format('H:mm'),
          quantity: checkoutData.partySize,
        }

        if (isUnifiedPaymentResponse(state.venueInfo.paymentType)) {
          if (response.status === PaymentResultStatus.RESULT_PENDING) {
            if (response?.redirect_url) {
              // extract to gatewayBase and override if needed
              window.location = response.redirect_url
            } else {
              dispatch(setPaymentPendingResponse(response))
              dispatch(hideLoading())
            }
          } else if (response.status === PaymentResultStatus.RESULT_SUCCESS) {
            dispatch(postCheckoutSuccess())
          } else {
            dispatch(postCheckoutFailure(response.message))
          }
        } else if (response.adyen_action_for_3d_secure && paymentType === AccountTypes.ADYEN) {
          dispatch(setDataFor3Dsecure(response.adyen_action_for_3d_secure))
          dispatch(hideLoading())
        } else if (paymentType === AccountTypes.CYBERSOURCE_3DS_REDUX && response.enrollment_result) {
          dispatch(payerAuthenticationCheckEnrollmentComplete(response))
        } else {
          dispatch(postCheckoutSuccess())
        }
      } else {
        // The following block of code reloads the Shift4 iframe, which is an unfortunately necessary hacky way to ensure that
        // the credit card fields can accept input after payment via Shift4 fails.
        if (paymentType === AccountTypes.SHIFT_4) {
          const shift4iframe = document.getElementById('i4goFrame')?.querySelector('iframe')
          if (shift4iframe) {
            shift4iframe.src += ''
          }
        }

        dispatch(cleanState(paymentType))
        dispatch(postCheckoutFailure(response.errors[0]))
        if (jsonData.is_stripe_payment_element) {
          dispatch(createStripeIntent())
        }
      }
    })
    .catch(() => {
      dispatch(cleanState(paymentType))
      dispatch(postCheckoutFailure('Unable to process request. Please try again'))
      if (jsonData.is_stripe_payment_element) {
        dispatch(createStripeIntent())
      }
    })
}

const cleanState = paymentType => dispatch => {
  if (paymentType === AccountTypes.CYBERSOURCE_3DS_REDUX || paymentType === AccountTypes.CYBERSOURCE_3DS) {
    dispatch(cleanCybersourcePaymentInformation())
  }
}

export const submitGiftCardPaymentCheckoutData = () => (dispatch, getState) => {
  const state = getState()
  const preppedData = prepChargeData(state)
  dispatch(submitCheckoutData(preppedData, null, true))
}

const updateAndSubmitPaymentElement = (stripe, elements, result, priceData, stripeIntentClientSecret) => (dispatch, getState) => {
  const state = getState()

  const intentForm = new FormData()
  intentForm.append('amount', priceData.amountDue)
  intentForm.append('token', result?.paymentIntent?.id)

  fetch(`/booking/widget/${state.venueInfo.venueId}/update_payment_intent`, {
    body: intentForm,
    method: 'POST',
    credentials: 'same-origin',
  })
    .then(response => response.json())
    // eslint-disable-next-line consistent-return
    .then(response => {
      if (response.errors) {
        let messageStringFinal = ''
        response.errors.forEach(errorItem => {
          const messageString = errorItem.split(':')
          messageStringFinal += `* ${messageString[1]}`
        })
        dispatch(stripeError(messageStringFinal))
      } else {
        elements.fetchUpdates().then(() => {
          dispatch(submitPaymentElement(stripe, elements, stripeIntentClientSecret))
        })
      }
    })
}

export const stripePaymentMethodSubmitHandler = (stripe, defaultStripeErrorMessage) => (dispatch, getState) => {
  const state = getState()
  const priceData = selectPricingData(state)
  const save = !priceData.amountDue

  const { elements, stripeIntentClientSecret } = state.commonPayment

  if (save) {
    dispatch(submitPaymentElement(stripe, elements, stripeIntentClientSecret, defaultStripeErrorMessage, true))
  } else {
    stripe.retrievePaymentIntent(stripeIntentClientSecret).then(result => {
      if (result?.paymentIntent?.amount !== priceData.amountDueCents) {
        dispatch(updateAndSubmitPaymentElement(stripe, elements, result, priceData, stripeIntentClientSecret))
      } else {
        dispatch(submitPaymentElement(stripe, elements, stripeIntentClientSecret, defaultStripeErrorMessage))
      }
    })
  }
}

const stripePaymentElementErrorEventHandler = (state, error) => dispatch => {
  const { paymentTypeMessage } = state.commonPayment
  const paymentTypeErrorMessage = `Something went wrong with ${paymentTypeMessage}. Please choose a different payment method and try again.`
  if (error?.type === 'validation_error') {
    dispatch(hideLoading())
  } else if (error?.type === 'invalid_request_error' && error?.message.includes(paymentTypeMessage)) {
    dispatch(stripeError(paymentTypeErrorMessage))
  }
}

const submitPaymentElement =
  (stripe, elements, stripeIntentClientSecret, defaultStripeErrorMessage, isSaveCard = false) =>
  (dispatch, getState) => {
    const state = getState()

    if (isSaveCard) {
      elements.submit().then(ev => {
        if (ev?.error) {
          dispatch(stripePaymentElementErrorEventHandler(state, ev.error))
          return
        }
        stripe
          .confirmSetup({
            elements,
            clientSecret: stripeIntentClientSecret,
            redirect: 'if_required',
          })
          .then(response => {
            if (response.error) {
              dispatch(stripeError(response.error?.message || defaultStripeErrorMessage))
            } else {
              const cardToken = response.setupIntent.id
              const preppedData = prepChargeData(state, cardToken, null, true)
              dispatch(submitCheckoutData(preppedData, null, true))
            }
          })
      })
    } else {
      elements.submit().then(ev => {
        if (ev?.error) {
          dispatch(stripePaymentElementErrorEventHandler(state, ev.error))
          return
        }
        stripe
          .confirmPayment({
            elements,
            clientSecret: stripeIntentClientSecret,
            redirect: 'if_required',
          })
          .then(response => {
            const { error } = response
            if (error) {
              dispatch(stripeError(error?.message || defaultStripeErrorMessage))
            } else {
              const cardToken = response.paymentIntent.id
              const preppedData = prepChargeData(state, cardToken, null, true)
              dispatch(submitCheckoutData(preppedData, null, true))
            }
          })
      })
    }
  }

export const createStripeIntent = () => (dispatch, getState) => {
  const state = getState()
  const priceData = selectPricingData(state)
  const venue = state.venueEntities[state.searchResults.get('timeSlotVenue')]
  const save = !priceData.amountDue
  const intentForm = new FormData()
  intentForm.append('amount', priceData.amountDue)

  if (save) {
    const url = `/booking/widget/${venue.venueId}/setup_intent`
    return new Promise(resolve => {
      let secret = ''
      fetch(url, {
        body: intentForm,
        method: 'POST',
        credentials: 'same-origin',
      })
        .then(response => response.json())
        .then(response => {
          if (response.errors) {
            let messageStringFinal = ''
            response.errors.forEach(errorItem => {
              const messageString = errorItem.split(':')
              messageStringFinal += `* ${messageString[1]}`
            })
            dispatch(stripeError(messageStringFinal))
          } else {
            secret = response.client_secret
            dispatch(setIntentClientSecret(secret))
            resolve({ intentClientSecret: secret })
          }
          return secret
        })
    })
  }
  const url = `/booking/widget/${venue.venueId}/payment_intent`
  intentForm.append('source', IntentSources[TransactionTypes.WEB_DINING_CHARGE])
  return new Promise(resolve => {
    let secret = ''
    fetch(url, {
      body: intentForm,
      method: 'POST',
      credentials: 'same-origin',
    })
      .then(response => response.json())
      // eslint-disable-next-line consistent-return
      .then(response => {
        if (response.errors) {
          let messageStringFinal = ''
          response.errors.forEach(errorItem => {
            const messageString = errorItem.split(':')
            messageStringFinal += `* ${messageString[1]}`
          })
          dispatch(stripeError(messageStringFinal))
        } else {
          secret = response.payload.client_secret
          dispatch(setIntentClientSecret(secret))
          resolve({ intentClientSecret: secret })
        }
        return secret
      })
  })
}

// eslint-disable-next-line consistent-return
export const submitPaymentCheckoutDataStripe = (event, stripe) => (dispatch, getState) => {
  const state = getState()
  const priceData = selectPricingData(state)

  const formData = new FormData()
  formData.append('amount', priceData.amountDue)
  dispatch(tryPostRequest(state))

  const defaultStripeErrorMessage = 'Unable to process request. Please try again'

  return dispatch(stripePaymentMethodSubmitHandler(stripe, defaultStripeErrorMessage))
}
export const submitPaymentCheckoutDataFreedomPay = () => (dispatch, getState) => {
  const state = getState()
  const formFields = state.formFields.toJS()
  const encrypt = new RSAEncrypt(FREEDOM_PAY_PUBLIC_KEY)

  const encryptedCard = encrypt.freedomPayCard(formFields.cardNum, formFields.cardMonthExp, formFields.cardYearExp, formFields.cardCvv)
  const preppedData = prepChargeData(state, encryptedCard)
  dispatch(submitCheckoutData(preppedData, null, true))
}

export const submitPaymentCheckout = (cardToken, encryptedCardData) => (dispatch, getState) => {
  const preppedData = prepChargeData(getState(), cardToken, encryptedCardData)
  dispatch(submitCheckoutData(preppedData, null, true))
}

export const submitPaymentCheckoutDataNetwork = () => (dispatch, getState) => {
  const state = getState()
  const formFields = state.formFields.toJS()

  const encryptor = new AESEncrypt(state.widgetSettings.encryptionKey)
  const piper = new PipeFormat()

  const { cardNum, cardMonthExp, cardYearExp, cardCvv, cardFirstName, cardLastName } = formFields
  const priceData = selectPricingData(state)
  const cardHolderName = `${cardFirstName} ${cardLastName}`
  const searchState = state.search.toJS()
  const timeSlotVenue = state.searchResults.get('timeSlotVenue')

  const request = piper.buildRequest(
    cardNum,
    cardMonthExp,
    cardYearExp,
    cardCvv,
    cardHolderName,
    priceData.finalAmount,
    'SALE',
    timeSlotVenue,
    `?venues=${getVenueKeysQueryString(searchState.venueMap)}`
  )

  const encryptedRequest = encryptor.encrypt(request)
  const preppedData = prepChargeData(state, encryptedRequest)
  dispatch(submitCheckoutData(preppedData, piper.processId, true))
}

export const submitPaymentCheckoutDataCybersourceRedux = () => (dispatch, getState) => {
  const state = getState()
  const searchState = state.search.toJS()
  const venue = state.venueEntities[searchState.selectedVenue]
  const formFields = state.formFields.toJS()
  const url = `/booking/widget/${venue.venueId}/jwk`
  fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
  })
    .then(response => response.json())
    .then(resp => {
      if (!resp?.kid) {
        dispatch(postCheckoutFailure(String('Failed to retrieve jwk key')))
      } else {
        const options = {
          kid: resp.kid,
          keystore: resp,
          encryptionType: 'rsaoaep256',
          cardInfo: {
            cardNumber: formFields.cardNum,
            cardType: CardCodes[stripeCard.cardType(formFields.cardNum).toUpperCase()],
            cardExpirationMonth: `0${formFields.cardMonthExp}`.substr(-2),
            cardExpirationYear: String(formFields.cardYearExp),
          },
          production: state.app.env === 'PRODUCTION',
        }
        flex.createToken(options, response => {
          if (response.error) {
            // eslint-disable-next-line no-alert
            alert('We could not process your card.')
            return
          }
          // eslint-disable-next-line no-param-reassign
          response.expiryMonth = formFields.cardMonthExp
          // eslint-disable-next-line no-param-reassign
          response.expiryYear = formFields.cardYearExp
          const preppedData = prepChargeData(state, response.token, response)
          dispatch(submitCheckoutData(preppedData, null, true))
        })
      }
    })
}

const startReduxAuthenticationServiceSetup = chargeData => (dispatch, getState) => {
  const state = getState()
  const { baseUrl } = state.widgetSettings
  const searchState = state.search.toJS()
  const venue = state.venueEntities[searchState.selectedVenue]
  const url = `${baseUrl}/api-yoa/cybersource/auth/${venue.venueId}/get_initial_data`
  fetch(url, {
    method: 'POST',
    body: JSON.stringify(chargeData),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 200) {
        dispatch(payerAuthenticationSetupComplete(response.data))
      } else {
        dispatch(postCheckoutFailure(response.msg))
      }
    })
    .catch(() => dispatch(postCheckoutFailure('Unable to process request. Please try again')))
}

export const submitPaymentCheckoutDataCybersourceThreeDSRedux = () => (dispatch, getState) => {
  const state = getState()
  const searchState = state.search.toJS()
  const venue = state.venueEntities[searchState.selectedVenue]
  const formFields = state.formFields.toJS()
  const url = `/booking/widget/${venue.venueId}/jwk`
  dispatch(tryPostCheckout(state))
  fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
  })
    .then(response => response.json())
    .then(resp => {
      if (!resp?.kid) {
        dispatch(postCheckoutFailure(String('Failed to retrieve jwk key')))
      } else {
        const options = {
          kid: resp.kid,
          keystore: resp,
          encryptionType: 'rsaoaep256',
          cardInfo: {
            cardNumber: formFields.cardNum,
            cardType: CardCodes[stripeCard.cardType(formFields.cardNum).toUpperCase()],
            cardExpirationMonth: `0${formFields.cardMonthExp}`.substr(-2),
            cardExpirationYear: String(formFields.cardYearExp),
          },
          production: state.app.env === 'PRODUCTION',
        }
        flex.createToken(options, response => {
          if (response.error) {
            // eslint-disable-next-line no-alert
            alert('We could not process your card.')
            return
          }
          // eslint-disable-next-line no-param-reassign
          response.expiryMonth = formFields.cardMonthExp
          // eslint-disable-next-line no-param-reassign
          response.expiryYear = formFields.cardYearExp
          const preppedData = prepChargeData(state, response.token, response)
          dispatch(startReduxAuthenticationServiceSetup(preppedData))
        })
      }
    })
}

export const paymentEnrollmentCybersourceThreeDs = (cardToken, cardData) => (dispatch, getState) => {
  const prepData = prepChargeData(getState(), cardToken, cardData)
  dispatch(submitCheckoutData(prepData, null, true))
}

export const submitPaymentCheckoutDataNetworkRedux = () => (dispatch, getState) => {
  const state = getState()
  const searchState = state.search.toJS()
  const venue = state.venueEntities[searchState.selectedVenue]

  dispatch(tryPostRequest(state))

  let paymentReference = null
  window.NI.generateSessionId()
    .then(response => {
      const formData = new FormData()
      const chargeData = prepChargeData(state)

      // These are required but unused except
      // for a sanity check that doesn't make
      // sense in this context, and cannot be
      // retrieved from NI's interface.
      // Relevant ln 2422 in booking_widget.py,
      // revisit.
      const formFields = state.formFields.toJS()
      chargeData.card_first_name = formFields.firstName
      chargeData.card_last_name = formFields.lastName
      formData.append('session_id', response.session_id)
      formData.append('amount', chargeData.charge_amount)
      formData.append('first_name', formFields.firstName)
      formData.append('last_name', formFields.lastName)
      formData.append('email_address', formFields.email)

      fetch(`/booking/widget/${venue.urlKey}/auth_charge`, {
        body: formData,
        method: 'POST',
        credentials: 'same-origin',
      })
        .then(response => {
          if (!response.ok && response.status === 500) {
            throw Error(`We encountered an error: ${response.status} ${response.statusText}`)
          }
          return response
        })
        .then(response => response.json())
        .then(response => {
          dispatch(toggleModalDisplay())
          if (response.errors) {
            throw Error(`We encountered an error: ${response.errors.join(', ')}`)
          }
          paymentReference = [response._id.split(':')[2], response.orderReference].join('|')
          return window.NI.handlePaymentResponse(response, {
            mountId: 'cc3dsMountId',
            style: {
              width: 500,
              height: 500,
            },
          }).then(response => {
            const { status } = response

            if (status === window.NI.paymentStates.AUTHORISED || status === window.NI.paymentStates.CAPTURED) {
              chargeData.card_token = paymentReference
              dispatch(submitCheckoutData(chargeData, null, true))
            } else if (status === window.NI.paymentStates.FAILED || status === window.NI.paymentStates.THREE_DS_FAILURE) {
              dispatch(postCheckoutFailure('Payment attempt was rejected'))
            }
          })
        })
        .catch(error => {
          dispatch(postCheckoutFailure(String(error)))
        })
    })
    .catch(error => {
      if (error && error.message) {
        dispatch(postCheckoutFailure(`Network International Error: ${error.message}`))
      } else {
        dispatch(postCheckoutFailure(String('Your session has expired. Please refresh the page and try again.')))
      }
    })
}

export const submitPaymentCheckoutDataNetworkReduxDirectPurchase = () => (dispatch, getState) => {
  const state = getState()
  dispatch(tryPostRequest(state))

  window.NI.generateSessionId()
    .then(response => {
      const chargeData = prepChargeData(state)
      const sessionId = response.session_id

      const formFields = state.formFields.toJS()
      chargeData.card_first_name = formFields.firstName
      chargeData.card_last_name = formFields.lastName
      chargeData.session_id = sessionId

      dispatch(submitCheckoutData(chargeData, null, true))
    })
    .catch(error => {
      if (error && error.message) {
        dispatch(postCheckoutFailure(`Network International Error: ${error.message}`))
      } else {
        dispatch(postCheckoutFailure(String('Your session has expired. Please refresh the page and try again.')))
      }
    })
}

const clientIdPostData = () =>
  _.reduce(
    ['tracking_slug', 'client_id', 'channel', 'experience_id', 'referral_id'],
    (accum, prop) => {
      // eslint-disable-next-line no-param-reassign
      accum[prop] = widgetInit[_.camelCase(prop)]
      return accum
    },
    {}
  )

const getPostUrl = (isModifyResMode, state, venueKey, actualId) => {
  if (state.modifyReservation.get('isModifyResUpgradesMode')) {
    return `${state.widgetSettings.baseUrl}/api-yoa/dining/pre_arrival_upgrades/${state.venueInfo.id}/${actualId}`
  }
  if (isModifyResMode) {
    return `${state.widgetSettings.baseUrl}/booking/dining/widget/${venueKey}/edit/${actualId}/book`
  }

  return `${state.widgetSettings.baseUrl}/booking/dining/widget/${venueKey}/book`
}

export const submitCheckoutData =
  (preppedData = null, processId = null, requirePaymentFields = false) =>
  (dispatch, getState) => {
    const state = getState()
    const { selectedTimeSlot, accessPersistentId, partySize } = state.search.toJS()
    const tags = state.entities.tags.toJS()
    const isModifyResMode = state.modifyReservation.get('isModifyResMode')
    const isRequestBookingMode = state.reservationRequest.get('isRequestBookingMode')
    const actualId = state.modifyReservation.get('actualId')
    const pricingData = selectPricingData(state)

    const postData = _.assign(
      {},
      prepResData(selectedTimeSlot, accessPersistentId, partySize, state),
      prepUserData(state),
      { ...(isModifyResMode && prepDataForModify(state)) },
      prepTags(tags),
      {
        ...((requirePaymentFields || pricingData.promoCodeDiscount) && (preppedData || prepChargeData(state))),
      },
      prepCaptchaData(state),
      { process_id: processId },
      clientIdPostData(),
      { widget_primary_venue_id: isModifyResMode ? null : state.venueInfo.id },
      { request_id: isRequestBookingMode ? state.reservationRequest.get('id') : null }
    )

    const formattedPostData = convertToFormData(postData)
    const venueKey = state.searchResults.get('timeSlotVenue')
    const postUrl = getPostUrl(isModifyResMode, state, venueKey, actualId)

    postCheckout(postUrl, formattedPostData, postData, dispatch, state)
  }

export const tryPostRequest = state => ({
  type: ActionTypes.TRY_POST_REQUEST,
  spinnerModalMessage: selectLanguageStrings(state).textSpinnerRequestProcessingLabel,
})

export const postRequestSuccess = () => ({
  type: ActionTypes.POST_REQUEST_SUCCESS,
})
export const postRequestFailure = errorMessage => ({
  type: ActionTypes.POST_REQUEST_FAILURE,
  errorMessage,
})

export const tryCheckout = () => (dispatch, getState) => {
  const state = getState()
  dispatch(tryPostRequest(state))
}

const postRequest = (url, formData, jsonData, dispatch, state) => {
  dispatch(tryPostRequest(state))

  const searchState = state.search.toJS()

  fetch(url, {
    body: formData,
    method: 'POST',
    credentials: 'same-origin',
  })
    .then(response => response.json())
    .then(response => {
      if (!response.errors) {
        AnalyticsEvents.requestSubmitted(
          extractCheckoutData(jsonData),
          getSelectedVenueFullName(searchState.selectedVenue, searchState),
          getVenueInternalName(searchState.selectedVenue, state)
        )
        dispatch(postRequestSuccess())
      } else {
        dispatch(postRequestFailure(response.errors[0]))
      }
    })
    .catch(() => {
      dispatch(postRequestFailure('Unable to process request. Please try again'))
    })
}

export const tryRedirect = () => ({ type: ActionTypes.TRY_REDIRECT })

export const submitRequest = () => (dispatch, getState) => {
  const state = getState()
  const postData = _.assign({}, prepRequestData(state), prepUserRequestData(state), clientIdPostData())

  const formattedPostData = convertToFormData(postData)
  const postUrl = `${state.widgetSettings.baseUrl}/booking/widget/${postData.venue_id}/request`
  postRequest(postUrl, formattedPostData, postData, dispatch, state)
}

export const tryGetOrReleaseReservationHold = () => ({
  type: ActionTypes.TRY_HOLD_RESERVATION,
})
export const holdReservationSuccess = (reservationHoldId, holdDurationSeconds, venueKey, shiftPersistentId, selectedTimeSlot) => ({
  type: ActionTypes.HOLD_RESERVATION_SUCCESS,
  reservationHoldId,
  holdDurationSeconds,
  venueKey,
  shiftPersistentId,
  selectedTimeSlot,
})
export const holdReservationFailure = (errorMessage, actionText, errorColor, actionCallback) => ({
  type: ActionTypes.HOLD_RESERVATION_FAILURE,
  errorMessage,
  actionText,
  errorColor,
  actionCallback,
})

export const submitHoldReservation = (venueKey, selectedTimeSlot, accessPersistentId) => (dispatch, getState) => {
  const state = getState()
  const dateStr = selectedTimeSlot.format('Y-MM-DD')
  const timeStr = selectedTimeSlot.format('h:mm A')
  const shiftPersistentId = state.availabilityLookup.getIn([venueKey, dateStr, timeStr, accessPersistentId, 'shiftPersistentId'])

  const postData = {
    venue: venueKey,
    ...prepHoldReservationData(selectedTimeSlot, accessPersistentId, shiftPersistentId, state),
    ...clientIdPostData(),
  }
  const postUrl = '/api-yoa/dining/hold/add'

  dispatch(tryGetOrReleaseReservationHold())

  return fetch(postUrl, {
    body: JSON.stringify(postData),
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(response => {
      if (response.status !== 200) {
        throw response.status
      }
      return response.json()
    })
    .then(response => {
      if (response.data) {
        // eslint-disable-next-line camelcase
        const { reservation_hold_id, hold_duration_sec } = response.data
        dispatch(holdReservationSuccess(reservation_hold_id, hold_duration_sec, venueKey, shiftPersistentId, selectedTimeSlot))
      } else {
        dispatch(holdReservationFailure(response.errors[0]))
        throw response.errors[0]
      }
    })
    .catch(e => {
      if (e === 470) {
        const searchAgainAction = revertStage(true)
        dispatch(
          holdReservationFailure(
            // not finding equivalent for these in GFL, hard coding for now
            'That time slot is no longer available. Would you like to select another time?',
            'Search Again',
            state.widgetSettings.colorPrimary,
            () => dispatch(searchAgainAction)
          )
        )
      } else if (e === 471) {
        dispatch(
          holdReservationFailure(
            // not finding equivalent for these in GFL, hard coding for now
            "Due to high demand, we're unable to process your booking at this time. Please try again later or select a different date.",
            'Try Again',
            state.widgetSettings.colorPrimary
          )
        )
      } else {
        dispatch(holdReservationFailure('Unable to process request. Please try again'))
      }
      throw e
    })
}

export const clearHoldReservation = () => ({
  type: ActionTypes.CLEAR_HOLD_RESERVATION,
})

export const postReleaseReservationHold = () => (dispatch, getState) => {
  const state = getState()
  if (_.isNil(state.reservationHold.get('reservationHoldId'))) {
    // eslint-disable-next-line no-console
    console.log('No hold to release')
    return
  }
  dispatch(clearHoldReservation()) // Clear immediately since the rest is non-blocking and user can start selecting another time immediately
  const venueKey = state.reservationHold.get('venueKey')
  const postData = prepReleaseReservationHoldData(state)
  const formattedPostData = convertToFormData(postData)
  const postUrl = `${state.widgetSettings.baseUrl}/booking/dining/widget/${venueKey}/release-reservation-hold`

  // eslint-disable-next-line no-console
  console.log('Releasing hold')
  // eslint-disable-next-line consistent-return
  return fetch(postUrl, {
    body: formattedPostData,
    method: 'POST',
    credentials: 'same-origin',
  })
    .then(response => response.json())
    .then(() => {
      // eslint-disable-next-line no-console
      console.log('Released hold')
    })
    .catch(() => {
      // eslint-disable-next-line no-console
      console.log('Error releasing hold')
    })
}

const fetchActual = (baseUrl, venueUrlKey, token) => {
  const url = `${baseUrl}/booking/widget/${venueUrlKey}/actual/${token}/view`

  return fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(response => response.json())
}

/** Fetching res to be modified * */
export const getActualBegin = () => ({ type: ActionTypes.LOAD_ACTUAL_START })
export const getActualSuccess =
  (actual, phoneNumberLocale, venueCountryCode, birthdayType, upsellsCategoryIds, shiftIntervalMinutes) => (dispatch, getState) => {
    const state = getState()
    const isModifyResUpgradesMode = state.modifyReservation.get('isModifyResUpgradesMode')
    const bookingDateTimeMoment = moment(`${actual.date} ${actual.arrival_time_display}`, 'MM/DD/YY h:mm A')
    const timeSlotVenue = state.search.get('selectedVenue')
    const venueLocale = state.venueInfo.municipality.locale

    dispatch({
      type: ActionTypes.LOAD_ACTUAL_SUCCESS,
      actual,
      phoneNumberLocale,
      venueCountryCode,
      birthdayType,
      shiftIntervalMinutes,
      venueLocale,
    })
    dispatch(selectQueryDate(moment(actual.date, 'MM/DD/YY').format('YYYY-MM-DD')))
    dispatch(selectQueryTime(moment(actual.arrival_time_display, ['h:mm A']), bookingDateTimeMoment, isModifyResUpgradesMode))
    dispatch(selectQueryPartySize(actual.total_guests))
    dispatch(selectQueryDuration(actual.duration))
    dispatch(setHasCreditCardOnFile(!!_.get(actual, 'payments_billing_profile', false)))

    if (isModifyResUpgradesMode) {
      const { categories } = state.upsells.entities
      const upsellCategories = categories ?? {}
      const shiftOrBookingAccessOptionalUpgradeCategoryIds = Object.keys(upsellCategories).filter(
        key => !upsellCategories[key].isRequired && upsellsCategoryIds.length && upsellsCategoryIds.includes(upsellCategories[key].id)
      )
      dispatch({
        type: ActionTypes.INITIALIZE_UPGRADES_STATE,
        actual,
        shiftOrBookingAccessOptionalUpgradeCategoryIds,
      })
      dispatch(selectQueryTimeSlotVenue(timeSlotVenue))
      dispatch(tryGetInitialAvailability(moment(actual.arrival_time_display, ['h:mm A'])))
    }
  }

export const getActualFail = errorMessage => ({
  type: ActionTypes.LOAD_ACTUAL_FAIL,
  errorMessage,
})

const setHasCreditCardOnFile = hasCreditCardOnFile => ({
  type: ActionTypes.SET_HAS_CREDIT_CARD_ON_FILE,
  hasCreditCardOnFile,
})

export const tryGetActual = () => (dispatch, getState) => {
  const state = getState()
  const venueUrlKey = state.modifyReservation.get('venueUrlKey')
  const token = state.modifyReservation.get('token')
  const isModifyResUpgradesMode = state.modifyReservation.get('isModifyResUpgradesMode')
  const { selectedLanguage } = state.languages

  dispatch(getActualBegin())

  return fetchActual(state.widgetSettings.baseUrl, venueUrlKey, token)
    .then(response => {
      const content = _.get(response, 'payload.content')

      if (content.is_past_res) {
        window.location.replace(`/direct/modify-reservation/${token}?lang=${selectedLanguage}`)
      }

      if (isModifyResUpgradesMode && content.has_passed_time_to_modify_upgrades) {
        window.location.replace(`/direct/modify-reservation/${token}?lang=${selectedLanguage}`)
      }

      return dispatch(
        getActualSuccess(
          content.actual,
          content.true_phone_number_locale,
          state.venueInfo.countryCode,
          state.widgetSettings.birthdayType,
          content.upsell_category_ids,
          content.shift_interval_minutes
        )
      )
    })
    .catch(e => {
      const errStr = e.toString()

      // don't want to expose these
      if (errStr.startsWith('TypeError') || errStr.startsWith('SyntaxError')) {
        return dispatch(getActualFail('An error has occurred fetching details, please try again later.'))
      }
      return dispatch(getActualFail(errStr))
    })
}

const fetchPromoCodes = (baseUrl, venueUrlKey, resDate, resTime) => {
  const url = `${baseUrl}/direct/${venueUrlKey}/get_promos?res_date=${resDate}&res_time=${resTime}`

  return fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(response => response.json())
}

export const getPromoCodesSuccess = promoCodes => ({
  type: ActionTypes.GET_PROMO_CODES_SUCCESS,
  promoCodes,
})

export const getPromoCodesFailure = errorMessage => ({
  type: ActionTypes.GET_PROMO_CODES_FAILURE,
  errorMessage,
})

export const tryGetPromoCodes = selectedSlot => (dispatch, getState) => {
  const state = getState()
  const { venueKey, timeSlotMoment } = selectedSlot
  const resDate = timeSlotMoment.format('Y-MM-DD')
  const resTime = timeSlotMoment.format('HH:mm')

  fetchPromoCodes(state.widgetSettings.baseUrl, venueKey, resDate, resTime)
    .then(response => {
      const content = _.get(response, 'payload.content')
      dispatch(getPromoCodesSuccess(content.promo_codes))
    })
    .catch(e => {
      // eslint-disable-next-line no-console
      console.log(e.toString())
      dispatch(getPromoCodesFailure('An error has occurred fetching promo details, please try again later.'))
    })
}

const getExperienceStart = () => ({
  type: ActionTypes.GET_EXPERIENCE_START,
})

const getExperienceSuccess = experience => ({
  type: ActionTypes.GET_EXPERIENCE_SUCCESS,
  experience,
})

const getExperienceFailure = message => ({
  type: ActionTypes.GET_EXPERIENCE_FAIL,
  message,
})

const fetchExperience = (baseUrl, experienceId, venueKey) => {
  const url = `${baseUrl}/api-yoa/experiences/${experienceId}?venue=${venueKey}`

  return fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(response => response.json())
}

const getReservationRequestStart = () => ({
  type: ActionTypes.GET_RESERVATION_REQUEST_START,
})

const getReservationRequestSuccess = reservationRequest => (dispatch, getState) => {
  const state = getState()

  const bookingDateTimeMoment = moment(reservationRequest.startDatetime, ['YYYY-MM-DD h mm a'])
  const timeSlotVenue = state.search.get('selectedVenue')
  const venueCountryCode = state.venueInfo.countryCode

  dispatch({
    type: ActionTypes.GET_RESERVATION_REQUEST_SUCCESS,
    reservationRequest,
    venueCountryCode,
  })
  dispatch(selectQueryDate(moment(bookingDateTimeMoment).format('YYYY-MM-DD')))
  dispatch(selectQueryTime(bookingDateTimeMoment, bookingDateTimeMoment))
  dispatch(selectQueryPartySize(reservationRequest.maxGuests))
  dispatch(selectQueryTimeSlotVenue(timeSlotVenue))
  dispatch(tryGetInitialAvailability(bookingDateTimeMoment))
}

const getReservationRequestFailure = errorMessage => ({
  type: ActionTypes.GET_RESERVATION_REQUEST_FAIL,
  errorMessage,
})

export const tryGetReservationRequest = requestId => (dispatch, getState) => {
  const state = getState()
  const venueId = state.venueInfo.id
  dispatch(initialize())
  dispatch(getReservationRequestStart())

  RequestService.getRequest({ requestId })
    .then(data => {
      if (data.venueId !== venueId) {
        dispatch(getReservationRequestFailure(`Your request was not found. request_id: ${requestId}`))
      } else if (!data.isActive) {
        dispatch(getReservationRequestFailure('Your request is no longer active'))
      } else {
        dispatch(getReservationRequestSuccess(data))
      }
    })
    .catch(e => {
      // eslint-disable-next-line no-console
      console.error(e.toString())

      dispatch(getReservationRequestFailure('An error has occurred fetching request, please try again later.'))
    })
}

export const tryGetExperience = experienceId => (dispatch, getState) => {
  const state = getState()
  const baseURL = state.widgetSettings.baseUrl
  const venueKey = state.venueInfo.venueUrlKey

  dispatch(getExperienceStart())

  fetchExperience(baseURL, experienceId, venueKey)
    .then(response => {
      dispatch(getExperienceSuccess(response.data))
    })
    .catch(e => {
      // eslint-disable-next-line no-console
      console.error(e.toString())
      dispatch(getExperienceFailure('An error has occurred fetching experience availability, please try again later.'))
    })
}

const getWaitlistEtasSuccess = (viewType, waitlistEtasByAccessRules, accessIdsToShiftIds, isVenueOpen, isWaitlistOpen) => ({
  type: ActionTypes.GET_WAITLIST_ETAS_SUCCESS,
  viewType,
  waitlistEtasByAccessRules,
  accessIdsToShiftIds,
  isVenueOpen,
  isWaitlistOpen,
})

const getWaitlistFailure = errorMessage => ({
  type: ActionTypes.GET_WAITLIST_ETAS_FAIL,
  errorMessage,
})

export const fetchWaitlistEtas = (baseUrl, venueKey) => {
  const url = `${baseUrl}/api-yoa/waitlist/quote_times?venue_id=${venueKey}`

  return fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(response => response.json())
}

export const tryGetWaitlistEtas = () => (dispatch, getState) => {
  const state = getState()
  const { baseUrl } = state.widgetSettings
  const venueKey = state.venueInfo.venueUrlKey
  dispatch({
    type: ActionTypes.GET_WAITLIST_ETAS_START,
    spinnerModalMessage: selectLanguageStrings(state).waitlistTextLoadingWaitlist,
  })
  fetchWaitlistEtas(baseUrl, venueKey)
    .then(response => {
      const waitlistEtasByAccessRules = {}
      const accessIdsToShiftIds = {}
      // eslint-disable-next-line camelcase
      response.data.quote_times.forEach(quote_time_options => {
        quote_time_options.options.forEach(opt => {
          if (!(opt.access_persistent_id in waitlistEtasByAccessRules)) {
            waitlistEtasByAccessRules[opt.access_persistent_id] = []
          }
          waitlistEtasByAccessRules[opt.access_persistent_id].push({
            estimated_wait_time: opt.quote_time,
            size: parseInt(quote_time_options.party_size, 10),
          })
          accessIdsToShiftIds[opt.access_persistent_id] = opt.shift_persistent_id
        })
      })
      let viewType = viewTypes.CHECKOUT_WAITLIST
      if (!response.data.venue_open) {
        viewType = viewTypes.VENUE_CLOSED
      } else if (Object.keys(waitlistEtasByAccessRules).length === 0) {
        viewType = viewTypes.WAITLIST_CLOSED
      } else if (Object.keys(waitlistEtasByAccessRules).length > 1) {
        viewType = viewTypes.CHOOSE_WAITLIST_EXPERIENCE
      }
      dispatch(
        getWaitlistEtasSuccess(
          viewType,
          waitlistEtasByAccessRules,
          accessIdsToShiftIds,
          response.data.venue_open,
          Object.keys(waitlistEtasByAccessRules).length !== 0
        )
      )
    })
    .catch(e => {
      // eslint-disable-next-line no-console
      console.error(e.toString())
      dispatch(getWaitlistFailure('An error has occurred fetching waitlist times, please try again later.'))
    })
}

const submitWaitlistSuccess = waitlistEntry => ({
  type: ActionTypes.SUBMIT_WAITLIST_SUCCESS,
  waitlistEntry,
})

const submitWaitlistFailure = errorMessage => ({
  type: ActionTypes.SUBMIT_WAITLIST_FAIL,
  errorMessage,
})

export const submitWaitlist = (baseUrl, venueKey, formData) => {
  const url = `${baseUrl}/api-yoa/waitlist`

  return fetch(url, {
    method: 'POST',
    body: formData,
    credentials: 'same-origin',
  }).then(response => response.json())
}

export const trySubmitWaitlist = () => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.SUBMIT_WAITLIST_START,
    spinnerModalMessage: selectLanguageStrings(getState()).waitlistWidgetAddingToWaitlistText,
  })
  const state = getState()
  const { baseUrl } = state.widgetSettings
  const venueKey = state.venueInfo.venueUrlKey
  const postData = prepWaitlistData(state)
  const formattedPostData = convertToFormData(postData)
  const { selectedLanguage, hasLangParam } = state.languages

  submitWaitlist(baseUrl, venueKey, formattedPostData)
    .then(response => {
      if (response.status !== 200) {
        throw new Error(response.msg)
      } else {
        dispatch(submitWaitlistSuccess(response.data.waitlist_entry))
        const waitlistEntryId = response.data.waitlist_entry.id
        const langParam = hasLangParam ? selectedLanguage && `&lang=${selectedLanguage}` : ''
        const waitlistEntryUrl = `/waitlist/${venueKey}?waitlist_id=${waitlistEntryId}${langParam}`
        window.history.pushState(null, '', waitlistEntryUrl)
      }
    })
    .catch(e => {
      dispatch(submitWaitlistFailure(e.toString()))
      dispatch(tryGetWaitlistEtas())
    })
}

const getWaitlistEntrySuccess = waitlistEntry => ({
  type: ActionTypes.GET_WAITLIST_ENTRY_SUCCESS,
  waitlistEntry,
})

const getWaitlistEntryFailure = errorMessage => ({
  type: ActionTypes.GET_WAITLIST_ENTRY_FAIL,
  errorMessage,
})

export const getWaitlistEntry = (baseUrl, venueKey, waitlistEntryId) => {
  const url = `${baseUrl}/api-yoa/waitlist/${venueKey}/${waitlistEntryId}`

  return fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(response => response.json())
}

export const tryGetWaitlistEntry = id => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.GET_WAITLIST_ENTRY_START,
  })
  const state = getState()
  const { baseUrl } = state.widgetSettings
  const venueKey = state.venueInfo.venueUrlKey

  getWaitlistEntry(baseUrl, venueKey, id)
    .then(response => {
      if ('phone_number' in response.data.waitlist_entry) {
        // eslint-disable-next-line no-param-reassign
        response.data.waitlist_entry.phone_number = removeCountryCodeFromPhone(
          response.data.waitlist_entry.phone_number,
          state.formFields.get('dialCode')
        )
      }
      dispatch(getWaitlistEntrySuccess(response.data.waitlist_entry))
    })
    .catch(e => {
      // eslint-disable-next-line no-console
      console.error(e.toString())
      dispatch(getWaitlistEntryFailure('An error has occurred fetching waitlist spot, please try again later.'))
    })
}

const cancelWaitlistEntrySuccess = waitlistEntry => ({
  type: ActionTypes.CANCEL_WAITLIST_ENTRY_SUCCESS,
  waitlistEntry,
})

const cancelWaitlistEntryFailure = errorMessage => ({
  type: ActionTypes.CANCEL_WAITLIST_ENTRY_FAIL,
  errorMessage,
})

export const cancelWaitlistEntry = (baseUrl, venueKey, waitlistEntryId) => {
  const url = `${baseUrl}/api-yoa/waitlist/${venueKey}/${waitlistEntryId}`

  return fetch(url, {
    method: 'PUT',
    credentials: 'same-origin',
  }).then(response => response.json())
}

export const tryCancelWaitlistEntry = () => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.CANCEL_WAITLIST_ENTRY_START,
    spinnerModalMessage: selectLanguageStrings(getState()).waitlistWidgetRemovingFromWatilistText,
  })
  const state = getState()
  const { baseUrl } = state.widgetSettings
  const venueKey = state.venueInfo.venueUrlKey

  cancelWaitlistEntry(baseUrl, venueKey, state.waitlist.get('id'))
    .then(response => {
      dispatch(cancelWaitlistEntrySuccess(response.data.waitlist_entry))
    })
    .catch(e => {
      // eslint-disable-next-line no-console
      console.error(e.toString())
      dispatch(cancelWaitlistEntryFailure('An error has occurred cancelling waitlist spot, please try again later.'))
    })
}

export const rejoinWaitlist = () => (dispatch, getState) => {
  const state = getState()
  const venueKey = state.venueInfo.venueUrlKey
  const { selectedLanguage, hasLangParam } = state.languages
  const langParam = hasLangParam ? selectedLanguage && `?lang=${selectedLanguage}` : ''
  const waitlistUrl = `/waitlist/${venueKey}${langParam}`
  window.history.pushState(null, '', waitlistUrl)
  dispatch({
    type: ActionTypes.REJOIN_WAITLIST,
  })
  tryGetWaitlistEtas()
}

export const showRedemptionError = ({ redemptionError, isFormDisabled }) => ({
  type: ActionTypes.SHOW_REDEMPTION_ERROR,
  redemptionError,
  isFormDisabled,
})

const getGiftCardBalance = ({
  baseUrl,
  venueKey,
  redemptionSystem,
  redemptionCardId,
  redemptionCardPin,
  redemptionCardEncryptionKey,
  redemptionCardEncryptionId,
}) => {
  const aesEncryptor = new AESEncrypt(redemptionCardEncryptionKey)
  const redemptionCardFields = [redemptionCardId, redemptionCardPin].join('|')
  const encryptedCardFields = aesEncryptor.encrypt(redemptionCardFields).split('+').join('-')
  const url =
    `${baseUrl}/api-yoa/redemption_hub/${venueKey}/check_balance?` +
    `redemption_system=${redemptionSystem}&` +
    `encrypted_card_fields=${encryptedCardFields}&` +
    `encryption_id=${redemptionCardEncryptionId}`

  return fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
  }).then(response => response.json())
}

export const viewRedemptionBalance = (redemptionCardId, redemptionCardPin) => (dispatch, getState) => {
  const state = getState()
  const { baseUrl } = state.widgetSettings
  const venueKey = state.venueInfo.venueUrlKey
  const { redemptionCardEncryptionKey } = state.widgetSettings
  const { redemptionCardEncryptionId } = state.widgetSettings
  const redemptionSystem = 'VALUTEC'

  dispatch({
    type: ActionTypes.TRY_VIEW_REDEMPTION_BALANCE,
  })

  return getGiftCardBalance({
    baseUrl,
    venueKey,
    redemptionSystem,
    redemptionCardId,
    redemptionCardPin,
    redemptionCardEncryptionKey,
    redemptionCardEncryptionId,
  })
    .then(({ data }) => {
      const error = data && data.error
      const balance = data && data.balance
      const resultBalance = _.toNumber(balance)

      if (error) {
        switch (error) {
          case 'INVALID_REDEMPTION_SYSTEM':
          case 'INVALID_CLIENT_KEY':
          case 'REDEMPTION_SYSTEM_NOT_ON':
            return dispatch(
              showRedemptionError({
                redemptionError: 'Invalid Gift Card Setup, please contact venue or try again.',
                isFormDisabled: true,
              })
            )
          case 'CARD_DEACTIVATED':
          case 'CARD_NOT_ACTIVE':
            return dispatch(
              showRedemptionError({
                redemptionError: 'Card Error: Card is not active, please try a different card.',
              })
            )
          case 'NOT_SUFFICIENT_FUNDS':
            return dispatch(
              showRedemptionError({
                redemptionError: 'Card Error: Insufficient funds, please update and try again.',
              })
            )
          case 'CARD_NOT_FOUND':
            return dispatch(
              showRedemptionError({
                redemptionError: 'Card Error: Card not found, please update and try again.',
              })
            )
          case 'INVALID_AMT':
            return dispatch(
              showRedemptionError({
                redemptionError: 'Invalid amount. Please update and try again.',
              })
            )
          case 'ENCRYPTION_ERROR':
            return dispatch(
              showRedemptionError({
                redemptionError: 'Session has expired. Please refresh page and try again.',
              })
            )

          default:
            return dispatch(
              showRedemptionError({
                redemptionError: 'Something went wrong. Please try again.',
              })
            )
        }
      } else if (_.isNumber(resultBalance) && !_.isNaN(resultBalance)) {
        const pricingData = selectPricingData(state)
        const { finalAmount } = pricingData
        const amountToRedeem = resultBalance >= finalAmount ? finalAmount : resultBalance

        return dispatch({
          type: ActionTypes.VIEW_REDEMPTION_BALANCE,
          redemptionCardBalance: resultBalance,
          amountToRedeem: _.isNumber(amountToRedeem) && !_.isNaN(amountToRedeem) ? amountToRedeem.toFixed(2) : undefined,
        })
      } else {
        return dispatch(
          showRedemptionError({
            redemptionError: 'Something went wrong',
          })
        )
      }
    })
    .catch(() =>
      dispatch(
        showRedemptionError({
          redemptionError: 'Something went wrong',
        })
      )
    )
    .finally(() => {
      dispatch({
        type: ActionTypes.VIEW_REDEMPTION_BALANCE_LOADED,
      })
    })
}

export const applyRedemption = redemption => (dispatch, getState) => {
  if (redemption) {
    const state = getState()
    const { redemptionCardBalance, amountToRedeem } = redemption
    const pricingData = selectPricingData(state)
    const { finalAmount } = pricingData

    if (_.isNil(amountToRedeem) || _.isNaN(amountToRedeem) || amountToRedeem <= 0) {
      return dispatch(
        showRedemptionError({
          redemptionError: 'Card Error. The amount to redeem is not valid.',
        })
      )
    }

    if (amountToRedeem > finalAmount) {
      return dispatch(
        showRedemptionError({
          redemptionError: 'Card Error. The amount to redeem exceeds total amount.',
        })
      )
    }

    if (redemptionCardBalance < amountToRedeem) {
      return dispatch(
        showRedemptionError({
          redemptionError: 'Card Error. The amount to redeem exceeds the amount available, please try again.',
        })
      )
    }

    return dispatch({
      type: ActionTypes.APPLY_REDEMPTION,
      redemption,
    })
  }

  return undefined
}

export const removeRedemption = index => dispatch =>
  dispatch({
    type: ActionTypes.REMOVE_REDEMPTION,
    index,
  })

export const showRedemptionForm = () => dispatch =>
  dispatch({
    type: ActionTypes.SHOW_REDEMPTION_FORM,
  })

export const clearRedemptionForm = () => dispatch =>
  dispatch({
    type: ActionTypes.CLEAR_REDEMPTION_FORM,
  })

export const cancelRedemptionForm = () => dispatch =>
  dispatch({
    type: ActionTypes.CANCEL_REDEMPTION_FORM,
  })

export const getVenueKeysQueryString = venueMap => _.filter(_.keys(venueMap), venueKey => venueKey !== ALL_LOCATIONS).join(',')

export const setFreedompayVisible = data => ({
  type: ActionTypes.SET_FREEDOMPAY_VISIBLE,
  data,
})

export const setAgreedToGdprDietaryOptIn = agreedToGdprDietaryOptin => ({
  type: ActionTypes.SET_AGREED_TO_GDPR_DIETARY_OPT_IN,
  agreedToGdprDietaryOptin,
})

export const setFreedompayInitialData = data => ({
  type: ActionTypes.SET_FREEDOMPAY_INITIAL_DATA,
  data,
})

const submitPaymentCheckoutDataWithToken = (token, cardData) => (dispatch, getState) => {
  dispatch(tryPostCheckout(getState()))
  const preppedCharge = prepChargeData(getState(), token, cardData)

  dispatch(submitCheckoutData(preppedCharge, null, true))
}

export const fetchOrRefreshFreedompayDataForIframe = (venueId, styles) => (dispatch, getState) => {
  const state = getState()
  const { baseUrl } = state.widgetSettings
  const url = `${baseUrl}/api-yoa/payments/${venueId}/begin_payment`

  const chargeData = prepChargeData(state)
  const formData = new FormData()
  const formFields = state.formFields.toJS()

  formData.append('total', chargeData.charge_amount * 100)
  formData.append('tax', chargeData.charge_tax * 100)
  formData.append('styles', styles)
  formData.append('payment_method', PAYMENT_CHANNELS.NEW_CREDIT_CARD)

  // will be ignored for non-hpc on BE
  formData.append('address1', chargeData?.address1)
  formData.append('city', chargeData?.city)
  formData.append('locality', chargeData?.locality)
  formData.append('administrativeArea', chargeData?.administrativeArea)
  formData.append('countryCode', chargeData?.countryCode)
  formData.append('postalCode', chargeData?.postalCode)

  formData.append('firstName', `${formFields.firstName}`)
  formData.append('lastName', `${formFields.lastName}`)

  formData.append('email', `${formFields?.email}`)
  formData.append('phone', `+${formFields?.dialCode}${formFields?.phoneNumber}`)
  // will be ignored for non-hpc on BE

  fetch(url, {
    body: formData,
    method: 'POST',
  })
    .then(response => response.json())
    .then(response => {
      dispatch(setFreedompayInitialData(response.data.begin_payment))
    })
}

export const submitFreedompayPaymentRequest = (venueId, freedompayData) => (dispatch, getState) => {
  const state = getState()
  const { baseUrl } = state.widgetSettings
  const authUrl = `${baseUrl}/api-yoa/payments/${venueId}/authorize`

  const chargeData = prepChargeData(state)
  const transactionType =
    chargeData.charge_amount || chargeData.amount_due ? TransactionTypes.WEB_DINING_CHARGE : TransactionTypes.WEB_DINING_SAVE

  const authData = new FormData()
  const attrs = freedompayData.attributes
  const maskedCard = attrs.find(attr => attr.Key === 'MaskedCardNumber').Value
  const postalCode = attrs.find(attr => attr.Key === 'PostalCode').Value
  const cardIssuer = attrs.find(attr => attr.Key === 'CardIssuer').Value
  const expirationDate = attrs.find(attr => attr.Key === 'ExpirationDate').Value
  const formFields = state.formFields.toJS()

  authData.append('paymentKey', freedompayData.paymentKeys[0])
  authData.append('sessionKey', state.payment.freedompay.initialData.sessionKey)
  authData.append('total', chargeData.charge_amount * 100)
  authData.append('tax', chargeData.charge_tax * 100)
  authData.append('name', `${formFields.cardFirstName} ${formFields.cardLastName}`)
  authData.append('transactionType', transactionType)

  // will be ignored for non-hpc on BE
  authData.append('address1', chargeData?.address1)
  authData.append('locality', chargeData?.locality)
  authData.append('administrativeArea', chargeData?.administrativeArea)
  authData.append('countryCode', chargeData?.countryCode)
  authData.append('postalCode', chargeData?.postalCode)

  authData.append('firstName', `${formFields.firstName}`)
  authData.append('lastName', `${formFields.lastName}`)

  authData.append('email', `${formFields?.email}`)
  authData.append('phone', `+${formFields?.dialCode}${formFields?.phoneNumber}`)
  // will be ignored for non-hpc on BE

  dispatch(tryPostCheckout(state))
  fetch(authUrl, {
    body: authData,
    method: 'POST',
    credentials: 'same-origin',
  })
    .then(response => response.json())
    .then(response => {
      if (response.status !== 200) {
        if (FREEDOMPAY_SOFT_DECLINE_CODES.includes(response.msg)) {
          window.FreedomPay.Apm.ConsumerAuth.invoke({ mandateChallenge: true })
          return
        }
        dispatch(postCheckoutFailure(response.msg))
        dispatch(fetchOrRefreshFreedompayDataForIframe(venueId))
        return
      }

      dispatch(
        submitPaymentCheckoutDataWithToken(response.data.authorization.token, {
          transactionId: response.data.authorization.transaction_id,
          invoiceId: response.data.authorization.invoice_id,
          maskedCard,
          expirationDate,
          postalCode,
          brand: cardIssuer,
          posSyncId: response.data.authorization.pos_sync_id,
          purchaserCode: response.data.authorization.purchaser_code,
          nameOnCard: response.data.authorization.name_on_card,
          sessionKey: response.data.authorization.session_key,
          transactionType,
        })
      )
    })
    .catch(err => {
      dispatch(postCheckoutFailure(err))
      dispatch(fetchOrRefreshFreedompayDataForIframe(venueId))
    })
}

const setLastConfirmationMethod = lastConfirmationMethod => ({
  type: ActionTypes.SET_LAST_CONFIRMATION_METHOD,
  lastConfirmationMethod,
})
